import React, { useCallback, useEffect, useState } from 'react';
import { usePdvOnline } from '~/pages/PdvOnline/hooks/usePdvOnline';
import { Modal } from '..';
import Loja from '~/components/Loja';
import useAuth from '~/hooks/useAuth';
import {
  BuscaParceiro,
  InputDate,
  InputNumber,
} from '~/components/NovosInputs';
import { CustomButtonNovo } from '~/components/Buttons/CustomButtonNovo';
import { IoIosSearch } from 'react-icons/io';
import { MdOutlineCancel } from 'react-icons/md';
import { FaEraser } from 'react-icons/fa';
import { CustomButtonsContainer, InputsContainer } from './styles';
import { toast } from 'react-toastify';
import { pedidoService } from '~/pages/PdvOnline/services/pedido';
import { GridPedidoPendente } from './components/GridPedidoPendente';
import { format } from 'date-fns';
import { moneyFormat } from '~/utils/functions';
import { GridPedidoItemPendente } from './components/GridPedidoItemPendente';
import { FaCheck } from 'react-icons/fa6';
import { PedidoProps } from '~/pages/PdvOnline/types/context';
import {
  FinalizadorasTableProps,
  ProdutoTableProps,
} from '~/pages/PdvOnline/types';
import { nanoid } from 'nanoid';
import { calcSubTotalProduto } from '../../TabProdutos/utils/calcSubTotalProduto';
import { useFocusTabProduto } from '~/pages/PdvOnline/hooks/useFocusTabProduto';
import { FinalizadorasPedidoProps } from '~/pages/PdvOnline/types/services';

export const ModalImportarPedido: React.FC = () => {
  const [loja, setLoja] = useState<number | undefined>();
  const [codPessoa, setCodPessoa] = useState<number | null>(null);
  const [fetching, setFetching] = useState(false);
  const [pedidoGrid, setPedidoGrid] = useState<any[]>([]);

  const { user } = useAuth();
  const {
    isModalOpen,
    onCloseModal,
    handleChangeOpenModalFunction,
    produtosTable,
    finalizadorasTable,
    handlePedidosPendentes,
    handleShowTablePedidosPendentes,
    showTablePedidosPendentes,
    pedidoPendenteSelected,
    handleAddProdutoTable,
    handlePedido,
    handleAddFinalizadoraTable,
    handleAddFinalizadoraPedido,
    foirmImportarPedido,
  } = usePdvOnline();

  const {
    getValuesImportarPedido,
    setErrorImportarPedido,
    registerImportarPedido,
    setValueImportarPedido,
    controlImportarPedido,
    formStateImportarPedido: { errors },
    clearErrorsImportarPedido,
  } = foirmImportarPedido;

  const { handleInputFocus } = useFocusTabProduto();

  const modalOpen =
    isModalOpen.isOpen &&
    isModalOpen.modal === 'importar-pedido' &&
    produtosTable.length === 0 &&
    finalizadorasTable.length === 0;

  const calcularTotal = useCallback(
    (itemArray: any[], arry: 'pedido' | 'financeiro'): number => {
      if (arry === 'financeiro') {
        return (itemArray || []).reduce((total, item) => {
          return total + (item.val_parcela || 0);
        }, 0);
      }

      return (itemArray || []).reduce((total, item) => {
        return total + (item.val_total || 0);
      }, 0);
    },
    [],
  );

  useEffect(() => {
    if (modalOpen && user) setLoja(user.loja);
  }, [modalOpen, user]);

  const onSearch = useCallback(async () => {
    if (!loja) {
      toast.warning('Loja deve ser informada');
      return;
    }

    if (
      !getValuesImportarPedido('dta_de') &&
      !getValuesImportarPedido('dta_ate')
    ) {
      setErrorImportarPedido('dta_de', { type: 'required' });
      setErrorImportarPedido('dta_ate', { type: 'required' });
      return;
    }

    if (
      !getValuesImportarPedido('dta_de') ||
      !getValuesImportarPedido('dta_ate')
    ) {
      if (!getValuesImportarPedido('dta_de'))
        setErrorImportarPedido('dta_de', { type: 'required' });
      if (!getValuesImportarPedido('dta_ate'))
        setErrorImportarPedido('dta_ate', { type: 'required' });
      return;
    }

    const payload = {
      cod_pessoa: codPessoa,
      dta_de: getValuesImportarPedido('dta_de'),
      dta_ate: getValuesImportarPedido('dta_ate'),
      num_pedido: getValuesImportarPedido('num_pedido'),
    };

    setFetching(true);

    const { data } = await pedidoService.getPedidoPendente(loja, payload);

    handlePedidosPendentes(data);

    if (data.length > 0) {
      handleShowTablePedidosPendentes(true, false);

      const itemsGrid = data.map((pedido) => ({
        id: pedido.cod_seq_pedido,
        num_pedido: pedido.num_pedido,
        qtd_pedido_item: pedido.qtd_pedido_item,
        nome_pessoa: pedido.nome_pessoa,
        val_pedido: moneyFormat(
          calcularTotal(
            pedido.des_json_pedido_financeiro ?? pedido.des_json_pedido_item,
            pedido.des_json_pedido_financeiro ? 'financeiro' : 'pedido',
          ).toFixed(2),
        ),
        dta_emissao: format(new Date(pedido.dta_emissao), 'dd/MM/yyyy'),
        cod_loja: Number(pedido.cod_loja),
      }));

      setPedidoGrid(itemsGrid);
    } else {
      toast.warning(
        'Nenhum registro encontrado conforme o critério informado.',
      );
    }

    setFetching(false);
  }, [
    calcularTotal,
    codPessoa,
    getValuesImportarPedido,
    handlePedidosPendentes,
    handleShowTablePedidosPendentes,
    loja,
    setErrorImportarPedido,
  ]);

  const onClear = useCallback(() => {
    if (user) setLoja(user.loja);

    ['num_pedido', 'dta_de', 'dta_ate'].forEach((name) => {
      setValueImportarPedido(name, '');
      clearErrorsImportarPedido(name);
    });

    setValueImportarPedido('busca_parceiro', { value: '', label: '' });
    clearErrorsImportarPedido('busca_parceiro');

    setCodPessoa(null);
    handlePedidosPendentes([]);
    setFetching(false);
    handleShowTablePedidosPendentes(false, false);
  }, [
    clearErrorsImportarPedido,
    handlePedidosPendentes,
    handleShowTablePedidosPendentes,
    setValueImportarPedido,
    user,
  ]);

  const handleCloseModal = useCallback(() => {
    onCloseModal();
    handleChangeOpenModalFunction(true);
    onClear();
  }, [handleChangeOpenModalFunction, onClear, onCloseModal]);

  const onCancel = useCallback(() => {
    handleShowTablePedidosPendentes(false, false);
    handlePedidosPendentes([]);
  }, [handlePedidosPendentes, handleShowTablePedidosPendentes]);

  useEffect(() => {
    const handleCloseModalKeyboard = (ev: KeyboardEvent) => {
      if (modalOpen && ev.key === 'Escape') handleCloseModal();
    };

    window.addEventListener('keydown', handleCloseModalKeyboard);
    return () =>
      window.removeEventListener('keydown', handleCloseModalKeyboard);
  }, [handleCloseModal, modalOpen]);

  const handleSelecionar = useCallback(() => {
    const pedidos: PedidoProps[] = [];
    const produtoTables: ProdutoTableProps[] = [];
    const novasParcelas: FinalizadorasTableProps[] = [];
    const novasParcelasPedido: FinalizadorasPedidoProps[] = [];

    if (pedidoPendenteSelected) {
      const { des_json_pedido_item, des_json_pedido_financeiro } =
        pedidoPendenteSelected;

      des_json_pedido_item.forEach((pi) => {
        const id = nanoid();
        const {
          cod_gtin,
          cod_produto,
          val_pedido: val_preco,
          val_desconto,
          qtd_pedido,
          des_produto,
          des_unidade,
          flg_ipv,
        } = pi;

        const pedido: PedidoProps = {
          id,
          cod_gtin,
          cod_produto,
          val_preco,
          val_desconto,
          qtd_pedido,
        };

        const val_total = moneyFormat(
          calcSubTotalProduto(
            String(qtd_pedido),
            String(val_preco),
            val_desconto,
            flg_ipv,
          ).toString(),
        );

        const produtotable: ProdutoTableProps = {
          id,
          cod_produto,
          des_produto,
          des_unidade_venda: des_unidade,
          qtd_produto: qtd_pedido,
          val_preco: moneyFormat(String(val_preco)),
          val_desconto: `${moneyFormat(val_desconto.toString())}%`,
          val_total,
        };

        pedidos.push(pedido);
        produtoTables.push(produtotable);
      });

      (des_json_pedido_financeiro || []).forEach((pf) => {
        const parcelaId = nanoid();

        const parcela: FinalizadorasTableProps = {
          id: parcelaId,
          num_prazo: pf.num_condicao,
          condicao: pf.des_condicao,
          des_finalizadora: pf.des_finalizadora,
          dta_vencto: format(new Date(pf.dta_vencimento), 'dd/MM/yyyy'),
          vlr_parcela: moneyFormat(pf.val_parcela.toFixed(2)),
          tipo_finalizadora: pf.tipo_finalizadora,
        };

        const parcelaPedido: FinalizadorasPedidoProps = {
          id: parcelaId,
          cod_finalizadora: pf.cod_finalizadora,
          cod_condicao: pf.cod_condicao,
          num_condicao: pf.num_condicao,
          val_parcela: pf.val_parcela,
          dta_vencimento: format(new Date(pf.dta_vencimento), 'dd/MM/yyyy'),
        };

        novasParcelas.push(parcela);
        novasParcelasPedido.push(parcelaPedido);
      });

      handleAddProdutoTable(produtoTables);
      handlePedido(pedidos);
      handleAddFinalizadoraTable(novasParcelas);
      handleAddFinalizadoraPedido(novasParcelasPedido);

      onCloseModal();
      handleInputFocus('leitor');

      toast.success('Pedido importado com sucesso');
    }
  }, [
    handleAddFinalizadoraPedido,
    handleAddFinalizadoraTable,
    handleAddProdutoTable,
    handleInputFocus,
    handlePedido,
    onCloseModal,
    pedidoPendenteSelected,
  ]);

  return (
    <Modal
      isOpen={modalOpen}
      title="Importar Pedido Pendente"
      onClose={handleCloseModal}
      size="lg"
    >
      <Loja
        onChange={(val: number) => {
          setLoja(val);
        }}
        resetLojas={false}
        selectedLoja={loja}
        disabled={fetching || showTablePedidosPendentes.pedido}
        isDisabled={fetching || showTablePedidosPendentes.pedido}
      />
      <InputsContainer>
        <InputNumber
          label="Nº Pedido:"
          maxLength={99999999}
          placeholder=""
          name="num_pedido"
          min={1}
          max={99999999}
          register={registerImportarPedido}
          disabled={fetching || showTablePedidosPendentes.pedido}
          isError={!!errors.num_pedido}
        />
        <InputDate
          label="Emissão:"
          placeholder=""
          name="dta_de"
          register={registerImportarPedido}
          control={controlImportarPedido}
          disabled={fetching || showTablePedidosPendentes.pedido}
          isError={!!errors.dta_de}
          onChange={(event: any) => {
            setValueImportarPedido('dta_de', event.target?.value);
            clearErrorsImportarPedido('dta_de');
          }}
        />
        <InputDate
          label=""
          placeholder=""
          name="dta_ate"
          register={registerImportarPedido}
          control={controlImportarPedido}
          disabled={fetching || showTablePedidosPendentes.pedido}
          isError={!!errors.dta_ate}
          onChange={(event: any) => {
            setValueImportarPedido('dta_ate', event.target?.value);
            clearErrorsImportarPedido('dta_ate');
          }}
        />
        <BuscaParceiro
          label="Cliente"
          id="busca_parceiro"
          placeholder="Informe o cliente..."
          name="busca_parceiro"
          register={registerImportarPedido}
          isError={!!errors.busca_parceiro}
          control={controlImportarPedido}
          disabled={fetching || showTablePedidosPendentes.pedido}
          customOptions={{ buscarPor: { cliente: true } }}
          changeSelected={(selected: any) => {
            const { value, label, cod_pessoa } = selected;
            setValueImportarPedido('busca_parceiro', { value, label });
            setCodPessoa(cod_pessoa);
            clearErrorsImportarPedido('busca_parceiro');
          }}
        />
      </InputsContainer>
      <CustomButtonsContainer>
        <CustomButtonNovo
          onClick={async () => {
            await onSearch();
          }}
          label="Pesquisar"
          icon={IoIosSearch}
          disabled={fetching || showTablePedidosPendentes.pedido}
          showLoading={fetching}
        />
        <CustomButtonNovo
          disabled={fetching || !showTablePedidosPendentes.pedido}
          onClick={onCancel}
          label="Cancelar"
          icon={MdOutlineCancel}
          variant="cancel"
        />
        <CustomButtonNovo
          onClick={onClear}
          disabled={fetching || showTablePedidosPendentes.pedido}
          label="Limpar"
          icon={FaEraser}
          variant="clear"
        />
      </CustomButtonsContainer>
      {showTablePedidosPendentes.pedido && (
        <section
          style={{ display: 'flex', flexDirection: 'column', gap: '0.625rem' }}
        >
          <GridPedidoPendente pedidoGrid={pedidoGrid} />
          {showTablePedidosPendentes.item && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '0.625rem',
              }}
            >
              <GridPedidoItemPendente />
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'flex-end',
                }}
              >
                <CustomButtonNovo
                  onClick={handleSelecionar}
                  disabled={false}
                  showLoading={false}
                  label="Selecionar"
                  icon={FaCheck}
                  style={{ backgroundColor: '#009E0F' }}
                />
              </div>
            </div>
          )}
        </section>
      )}
    </Modal>
  );
};
